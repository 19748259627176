/* SKR-ALL-STYLES.CSS */

/* 
Extra Small (xs): Up to 576 pixels.
Small (sm): 576 pixels and up.
Medium (md): 768 pixels and up.
Large (lg): 992 pixels and up.
Extra Large (xl): 1200 pixels and up. 
*/

/* Dark Mode / Light Mode */
@media (prefers-color-scheme: dark) {
  :host, :root {
    z-index: 1 !important;
    /* position: fixed !important; */
    --skr-mobile: 600px; 
    --skr-desktop: 1200px; 
    --skr-font-min: 16px; 
    --skr-font-max: 20px;
    font-family: var(--font-family-sans-serif);
    font-family: "Roboto";
    font-size: 16px !important;
    letter-spacing: 2.2px;
    color:#fff !important;
    --skr-getBO-filter: invert(28%) sepia(80%) saturate(7037%) hue-rotate(1deg) brightness(105%) contrast(98%); 
    --skr-onBO-filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(120%) contrast(120%);

    --body-bg: #010101 !important;
    --body-color: #fff !important;
    --card-bg: #010101ef !important;
    --skr-link: color: #0dcaf0 !important;
    --body-link-color: #81c9fa !important; 
    --skr-red: #FC2504;
    --skr-z-level: 1 !important;
    --skr-text-color:  #fff; 
    --skr-bg-color: #010101 !important;
    background-color: #010101 !important;
     }
     /* 121212 */

  body{
    --skr-red: #FC2504;
    --skr-bg-color: #010101;
    --skr-text-color:  #fff; 
    color:#fff !important;
  }

  a:link{ color: #0dcaf0 !important; }
  a:link:visited{ color: #fff !important; }
  a:link:hover{ color: var(--skr-red) !important; }
  a:link:active{ color: #0dcaf0 !important; }

  
  .card {
    padding-left: 0px;
    background-color: #010101 !important;
    color:#fff !important;
  }

}

@media (prefers-color-scheme: light) {
  :host, :root  {
    z-index: 1 !important;
    /* position: fixed !important; */
    --skr-mobile: 600px; 
    --skr-desktop: 1200px; 
    --skr-font-min: 16px; 
    --skr-font-max: 20px;
    font-family: var(--font-family-sans-serif);
    font-family: "Roboto";
    font-size: 16px !important;
    letter-spacing: 2.2px;
  
    --body-bg: #fff !important;
    --body-link-color: #0dcaf0 !important; 
    --card-bg: #fff !important;
    --skr-red: #FC2504;
    background-color:  rgb(255, 255, 255) !important;
    --skr-bg-color: rgb(255, 255, 255) !important;
    color:#555 !important;
    --skr-text-color:  #555 !important; 
    --body-color: #555 !important;
    --skr-z-level: 2147482647 !important;
    --skr-getBO-filter: invert(28%) sepia(80%) saturate(7037%) hue-rotate(1deg) brightness(105%) contrast(98%); 
    --skr-onBO-filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(120%) contrast(120%);

     }

  body{
    --skr-red: #FC2504;
    --skr-bg-color: #202123;
    --skr-text-color:  #fff; 
    color:#fff !important;
  }
  
  /* RESET FOR MEDIA QURIERS */
  /* body{
    --skr-red: #FC2504;
    --skr-bg-color: rgb(255, 255, 255);
    --skr-text-color:  #555; 
  } */

  .card {
    border: none !important;
    padding: 0px !important;

  }

}

/* TOP LEVEL CONTAINERS (to fix overlay problems) */
#skr-host {
  z-index: 2147483647;
}

.skr-crx-container > * {
  /* Set Minimum Font Size */
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 5px;
  background-color: var(--skr-bg-color, #343541) ; 
  color: var(--skr-text-color, #555) ; 
  border-top: 2px solid #8c8b8b;
  border-left: 2px solid #8c8b8b;
  box-shadow: -5px -5px 25px 12px rgba(199, 201, 197, 0.45);;
  border-radius: 5px ;
  margin: 0;
  padding: 0;
  user-select: auto ;
  pointer-events: auto; 
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
}

.skr-card-container {
  overflow: hidden !important;
  width: 100%;
  max-width: 100%;
}

.skr-site-link {
  size: 22px;
  color: var(--skr-link); 
  cursor: pointer;
}

.skr-offer-action {
  size: 22px;
  cursor: pointer;
}

.skr-all-offers {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 5px;
  width: 100%;
  background-color: rgb(248 250 251);
}

/* MESSAGING */
.skr-logo-header {
  /* position:absolute; */
  background-image: true;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 400px;
  align-self: center;
  /* height: 190px; */

}

.skr-logo-hero {
  /* position:absolute; */
  background-image: true;
  max-width: 400px;
  align-self: center;
  top: 50%;
  left: 50%;
  /* height: 190px; */

}


/* Product Viewer */

.swiper-container {
  display: flex;
  height: 100%; 
  width: 100%;
  max-height: 100%;
  max-width: 100%; 
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  align-items: left;
  justify-content: center; /* Center images horizontally */
}

.swiper-slide {
  width: auto ;
  height: auto;
  max-height: 100%;
  max-width: 100% ; 
}


/* .swiper-slide {
  display: flex;
  width: 100% !important;
  flex-shrink: 0;
  max-width: 100% !important; 
  height: 100%;
  max-height: 100%;
}
 */
/* Setting width fixes bug in swiper component */
.swiper-wrapper{
  display: flex;
  max-height: 100%;
  height: 100%;
  max-height: 100%;
  margin-left: 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100% !important;
  max-width: 100% !important; 
  /* max-width: 600px;    */
}

.swiper-pagination {
  /* Custom positioning styles for the pagination element */
  position: sticky;
  align-items: center;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1;
  margin: 0 8px;
  display: inline-block;
}

.swiper-pagination-bullet-active {
  background-color: #FC2504 !important;
  opacity: 1;
  margin: 0 8px;
}

.row-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px; /* Adjust the value to set the desired gap size */
}
/* SKR-Card Layouts */
.skr-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  font-style: bold;
  font-weight: 400;
  font-size: 1.4rem;
}

.skr-card-body {
  padding-top: 0px;
  padding-bottom: 0px;
  /* overflow-y: hidden; */
  max-height: 950px;
}

.skr-card-row {
  font-size: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}

.skr-card-title {
  /* position: sticky;
  position: -webkit-sticky; */
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 0px;
  font-style: bold;
  font-weight: 500;
  /* font-size: calc(var(--skr-font-min) * 1.3) !important; */
  font-size: calc(1.25rem + .9vw);
  line-height: 1.2;
  letter-spacing: .15rem;  
  text-transform: uppercase;
  text-align: center ;
}

.skr-offer-title-row {
  /* position: sticky;
  position: -webkit-sticky; */
  padding-bottom: 10px;
  margin-left: 0px;
  font-style: bold;
  font-weight: 425;
  /* font-size: calc(var(--skr-font-min) * 1.3) !important; */
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: .15rem;  
  text-transform: uppercase;
  text-align: left ;
}

.skr-card-title-urgent {
  /* position: sticky;
  position: -webkit-sticky; */
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 0px;
  font-style: bold;
  font-weight: 600;
  font-size: calc(var(--skr-font-min) * 1.5) !important;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: .15rem;  
  text-transform: uppercase;
  text-align: center ;
  color: #FC2504;

}

/* Set text to background to insert blank div */
.skr-clear-row {
  /* color: var(--background-color) ; */
  color:#fff !important; 
}

.skr-notification-container {
  position: absolute;
  z-index: calc(var(--skr-z-level) +50);
  background-color: var(--background-color) ; 
  font-size: calc(var(--skr-font-min) * 1.3) !important;
  font-style: bold;
  font-weight: 900;
  left:5;
  top:5;
  /* height: 28px;  */
  border-radius: 5px ;
}
.skr-prod-actions-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: middle;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-bottom: 5px;
  font-style: bold;
  font-weight: 900;
  font-size: calc(var(--skr-font-min) * 1.4) !important;
  /* font-size: 1.4rem; */
  line-height: 1.5;
  /* letter-spacing: .22rem;   */
  text-transform: uppercase;
  text-align: start ;
  align-content: middle;
}

.skr-product-img-close {
  position: absolute;
  z-index: 20;
  left: 0;
  color: #fff !important;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 900;
  cursor: pointer;
}

.skr-prod-actions {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-bottom: 5px;
  font-style: bold;
  font-weight: 900;
  font-size: calc(var(--skr-font-min) * 1.4) !important;
  /* font-size: 1.4rem; */
  line-height: 1.5;
  /* letter-spacing: .22rem;   */
  text-transform: uppercase;
  text-align: start ;
  align-content: middle;
}

.skr-prod-description {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: calc(var(--skr-font-min) * 1.0) !important;
  line-height: 1;
  letter-spacing: .10rem;  
  text-transform: none;
  text-align: start ;
}

.skr-summary-title {
  /* position: sticky;
  position: -webkit-sticky; */
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 0px;
  font-style: bold;
  font-weight: 500;
  /* font-size: calc(var(--skr-font-min) * 1.3) !important; */
  font-size: calc(1.25rem + .9vw);
  line-height: 1.2;
  letter-spacing: .15rem;  
  text-transform: uppercase;
  text-align: center ;
}

.confidence-text {
  font-size: 18px;  /* adjust as needed */
  vertical-align: middle;
}

.skr-summary-bottom-line-savings-urgent{
  margin-left: 0px;
  margin-bottom: 5px;
  margin-top: 1px;
  font-style: bold;
  font-weight: 500;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  line-height: 1.2;
  letter-spacing: .15rem;  
  text-transform: uppercase;
  text-align: middle ;
  background: #FC2504;
  border: solid 0.5px#FC2504;
  border-radius: 5px;
  max-width: 280px; 
}

.skr-summary-bottom-line-savings{
  margin-left: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-style: bold;
  font-weight: 400;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  line-height: 1.2;
  letter-spacing: .15rem;  
  text-align: middle ;
  max-width: 280px; 

}

.skr-summary-bottom-line {
  /* position: sticky;
  position: -webkit-sticky; */
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-left: 0px;
  font-style: bold;
  font-weight: 500;
  /* font-size: calc(var(--skr-font-min) * 1.3) !important; */
  font-size: calc(1.15rem + .9vw);
  line-height: 1.2;
  letter-spacing: .15rem;  
  text-transform: uppercase;
  text-align: center ;
}

.skr-summary-description {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  line-height: 1.2;
  letter-spacing: .10rem;  
  text-transform: none;
  text-align: start ;
}


.skr-prod-detail-img {
  position:absolute;
  z-index: calc(var(--skr-z-level) +0);
  border-radius: 5px;
  background-image: true;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  overflow: scroll;

}

.skr-prod-detail-swiper {
  position:relative;
  z-index: calc(var(--skr-z-level) +4);
  border-radius: 5px;
  /* background-image: true; */
  padding-right: 0px;
  padding-left: 0px;
  width: 100vw;
  height: 100vh;
  text-align: left;

}

.skr-actions {
  padding-top: 12px;
}

.skr-actions-highlight svg {
  /* color: var(--body-link-color) !important; */
  color: #05e9f9 !important;
  font-weight: 900;

}
/* var(--skr-link) */

.skr-histogram {
  position: relative;
  width: 100%;
  height: 250px;
}

.skr-histogram-img-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.skr-histogram-img {
  /* position:absolute; */
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  max-width: 180px;   
  object-fit: cover;
}

.skr-histogram-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  list-style: none;
  z-index: 1;
  padding: 0;
  margin: 0;
}

.skr-histogram-price-point{
  font-size: calc(var(--skr-font-min) * 1.3) !important;
  font-style: bold;
  font-weight: 400;
  z-index: 5;
  margin-left: 20px;
  line-height: 2;
  letter-spacing: .22rem;
}
.skr-histogram-price {
  font-size: calc(var(--skr-font-min) * 1.3);
  font-weight: bold;
  margin-right: 4px;
}

.skr-histogram-sellers {
  font-size: calc(var(--skr-font-min) * 0.9);
}

.skr-img-inset {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.skr-summary-img-inset {
  /* position:absolute; */
  border-radius: 5px;
  background-image: true;
  padding-right: 0px;
  padding-left: 0px;
  /* height: 190px; */
  width: 100%;  
  height: 100%;
  max-width: 280px; 
  max-height: 250px; 
  z-index: calc(var(--skr-z-level)+0);
  cursor: pointer;
  object-fit: contain;
}

.skr-prod-img-inset {
  /* position:absolute; */
  flex: 1;
  /* object-fit: contain;   */
  border-radius: 5px;
  background-image: true;
  padding-right: 0px;
  padding-left: 0px;
  /* height: 190px; */
  max-width: 280px; 
  max-height: 250px;
  object-fit: contain;
  z-index: calc(var(--skr-z-level)+0);
  cursor: pointer;
}

.skr-prod-img-inset-og {
  flex: 1;
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.skr-prod-img-expand {
  position:absolute;
  border-radius: 5px;
  background-image: true;
  padding-right: 0px;
  padding-left: 0px;
  /* height: 190px; */
  max-width: 180px; 
  z-index: calc(var(--skr-z-level)+0);
  cursor: pointer;
}

.skr-prod-limited {
  padding-bottom: 7px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: calc(var(--skr-font-min) * 1.6) !important;
  color: #FC2504;
  /* font-size: 1.3rem; */
  line-height: 1;
  letter-spacing: 1.023vw;  
}

.skr-prod-name{
  margin-left: 0px;
  margin-bottom: 5px;
  font-style: bold;
  font-weight: 400;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  line-height: 1;
  letter-spacing: .15rem;  
  text-transform: uppercase;
  text-align: start ;
}

.skr-prod-bottom-line-urgent{
  margin-left: 0px;
  margin-bottom: 5px;
  margin-top: 1px;
  font-style: bold;
  font-weight: 500;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  line-height: 1.2;
  letter-spacing: .25rem;  
  text-transform: uppercase;
  text-align: middle ;
  background: #FC2504;
  border: solid 0.5px#FC2504;
  border-radius: 5px;
  max-width: 280px; 

}

.skr-prod-bottom-line{
  margin-left: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-style: bold;
  font-weight: 400;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  line-height: 1.2;
  letter-spacing: .25rem;  
  text-align: middle ;
  max-width: 280px; 

}

.skr-prod-pricing {
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 10px;
  font-style: bold;
  font-weight: 400;
  font-size: calc(var(--skr-font-min) * 1.3) !important;
  /* font-size: 1.3rem; */
  line-height: 2;
  letter-spacing: .22rem;  
  text-transform: uppercase;
  text-align: start ;
}

.skr-prod-price-old {
  color: red;
  text-decoration: line-through;
  font-size: calc(var(--skr-font-min) * 1) !important;
}

.skr-search-error {
  color: red;
  font-size: var(--font-size-h1) !important;
  background: none !important;  /* Remove background */
  border: none !important;      /* Remove border */
  box-shadow: none !important;  /* Remove box shadow, if any */
  padding: 0 !important;        /* Remove padding */
  margin: 0 !important;         /* Remove margin */
  margin-bottom: 1em !important;
}

.skr-notification {
  font-size: var(--font-size-h1) !important;
  background: none !important;  /* Remove background */
  border: none !important;      /* Remove border */
  box-shadow: none !important;  /* Remove box shadow, if any */
  padding: 0 !important;        /* Remove padding */
  margin: 0 !important;         /* Remove margin */
  margin-bottom: 1em !important;
}

.skr-prod-pricing-inset {
  margin-top: 30px;
  margin-left: 0px;
  margin-bottom: 10px;
  font-style: bold;
  font-weight: 400;
  font-size: calc(var(--skr-font-min) * 1.3) !important;
  /* font-size: 1.3rem; */
  line-height: 2;
  letter-spacing: .22rem;  
  text-transform: uppercase;
  text-align: start ;
}

.skr-prod-savings {
  /* position: sticky;
  position: -webkit-sticky; */
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  /* font-size: 1.2rem; */
  line-height: 1.5;
  /* letter-spacing: .22rem;   */
  text-transform: uppercase;
  text-align: start ;
  align-content: middle;
}

.skr-prod-shipping {
  font-size: var(--skr-font-min) !important;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 100;
  font-size: calc(var(--skr-font-min) * 0.9) !important;
  /* font-size: 0.9rem; */
  line-height: 1.2;
  letter-spacing: .10rem;  
  text-transform: uppercase;
  text-align: start ;
}

.skr-prod-tag-insert {
  position: absolute;
  background-color: #FC2504;
  font-size: calc(var(--skr-font-min) * 1.3) !important;
  /* font-size: 1rem; */
  text-decoration-line: none;
  text-decoration: none;
  padding: 5px ;
  color:#fff;
  left:5;
  bottom:5;
  height: 35px; 
  width: 60px; 
  opacity: 1;
  border-radius: 5px ;
  text-align: center ;
}

.skr-prod-tag-overlay {
  position: absolute;
  z-index: calc(var(--skr-z-level) +5);
  background-color: #FC2504;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  font-weight: 500;
  letter-spacing: .1rem;  
  text-decoration-line: none;
  text-decoration: none;
  padding: 7px ;
  color:#fff;
  height: 35px; 
  width: 60px; 
  opacity: 1;
  border-radius: 5px ;
  text-align: center ;
}

.skr-price-tag-best {
  position: absolute;
  z-index: calc(var(--skr-z-level) +5);
  background-color: #FC2504;
  font-size: calc(var(--skr-font-min) * 1.0) !important;
  /* font-size: 1rem; */  
  font-style: bold;
  letter-spacing: .1rem;  
  text-decoration-line: none;
  text-decoration: none;
  padding: 5px ;
  color:#fff;
  left:5;
  bottom:5;
  height: 32px; 
  width: 100px; 
  opacity: 1;
  border-radius: 5px ;
  text-align: center ;
}

.skr-price-tag-overlay {
  position: absolute;
  z-index: calc(var(--skr-z-level) +5);
  background-color: #FC2504;
  font-size: calc(var(--skr-font-min) * 1.1) !important;
  font-weight: 500;
  letter-spacing: .1rem;  
  text-decoration-line: none;
  text-decoration: none;
  padding: 5px ;
  color:#fff;
  height: 32px; 
  width: 65px; 
  opacity: 1;
  border-radius: 5px ;
  text-align: center ;
}

.skr-prod-title {
  /* padding-left: 0px; */
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  margin-top: 5px;
  margin-bottom: 5px;
  font-style: bold;
  font-weight: 400;
  /* font-size: 1.2rem; */
  line-height: 1;
  letter-spacing: .2rem;  
  text-transform: uppercase;
  text-align: start ;
}

.skr-red-bar {
  text-align: start;
  font-weight: 600;
  color: #FC2504;
}

.skr-swipe-indicator {
  text-align: start;
  padding-bottom: 10px;
}

/* NAVBAR SPECIFIC */
.skr-nav-container {
  position: absolute;
  z-index: calc(var(--skr-z-level) +50);
  background-color: var(--skr-bg-color, #fff) ; 
  font-size: calc(var(--skr-font-min) * 1.0) !important;
  font-style: normal;
  font-weight: 700;
  width: 40%;
  left: 5;
  top:5;
  /* height: 28px;  */
  border-radius: 5px ;
  border-color: 2px solid #8c8b8b;
}

.skr-nav-bar-hamburger {
  padding-right: 5px;
  align-items: center;
}

.skr-nav-bar-lable {
  height: 30px;
  align-items: center;
}

.skr-nav-bar-icon {
  padding-bottom: 5px;
  align-items: center;
}

/* CONTAINER STYLES */
.skr-marker-container {
  position: fixed;
  z-index: calc(var(--skr-z-level) +0) !important;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
  padding-right: 20px;
  pointer-events: "all"
}

.skrmarker {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
  padding-right: 20px;
  pointer-events: "all"
}

/* FOREGROUND */
.crxmodal {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  /* bottom: 0; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  user-select: none;
  pointer-events: none;
  overflow: hidden;
  z-index: 99999999999;
}

.crxContainer {
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.skrContainer > * {
  /* Set Minimum Font Size */
  --skr-mobile: 600px; 
  --skr-desktop: 1200px; 
  --skr-font-min: 16px; 
  --skr-font-max: 20px;
  font-family: var(--font-family-sans-serif);
  font-family: "Roboto";
  font-size: var(--skr-font-min) !important;
  background-color: var(--background-color) ; 
  border-top: 2px solid #8c8b8b;
  border-left: 2px solid #8c8b8b;
  border-radius: 5px ;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--skr-z-level) !important;
  bottom: 0;
  right: 0;
  max-width: 600px;
  user-select: auto ;
  pointer-events: auto; 
  overflow-x: hidden;
  overflow-y: scroll;
}


/* MARKER PREVIEW */
.skr-marker-preview-getBO {
  position: inherit;
  z-index: calc(var(--skr-z-level)) !important;
  background-color: #FC2504;
  font-size: calc(var(--skr-font-min) * 1.5) !important;
  /* font-size: 1rem; */  
  font-style: bold;
  letter-spacing: .25rem;  
  text-decoration-line: none;
  text-decoration: none;
  padding: 7px ;
  color:#fff;
  height: 42px; 
  width: 66px; 
  opacity: 1;
  border-radius: 5px ;
  text-align: center ;
  pointer-events: "all";
  cursor: pointer;
}

.skr-marker-preview-onBO {
  position: inherit;
  z-index: calc(var(--skr-z-level)) !important;
  background-color: #569a05;
  font-size: calc(var(--skr-font-min) * 1.5) !important;
  /* font-size: 1rem; */  
  font-style: bold;
  letter-spacing: .25rem;  
  text-decoration-line: none;
  text-decoration: none;
  padding: 7px ;
  color:#fff;
  height: 42px; 
  width: 66px; 
  opacity: 1;
  border-radius: 5px ;
  text-align: center ;
  pointer-events: "all";
  cursor: pointer;
}

.skr-marker-preview-on {
  position: inherit;
  z-index: calc(var(--skr-z-level)) !important;
  background-color: #a8aca5;
  font-size: calc(var(--skr-font-min) * 1.5) !important;
  /* font-size: 1rem; */  
  font-style: bold;
  letter-spacing: .25rem;  
  text-decoration-line: none;
  text-decoration: none;
  padding: 7px ;
  color:#fff;
  height: 42px; 
  width: 66px; 
  opacity: 1;
  border-radius: 5px ;
  text-align: center ;
  pointer-events: "all";
  cursor: pointer;

}

.skr-marker-underlay {
    z-index: -20;
    position: absolute;
  }

/* Marker */
.skr-marker-container {
  position: fixed;
  z-index: var(--skr-z-level) !important;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
  padding-right: 20px;
  pointer-events: "all"
}

/* Marker-ON States */
.skr-marker-on-appear {
  filter: grayscale(100%);
  opacity: 20%;
}

.skr-marker-on-appear-active {
  transform: rotate(0turn);
  opacity: 75%;
  transition: all 1500ms;
}

.skr-marker-on-appear-done {
  /* transform: rotate(-0.25turn); */
  opacity: 75%;
  filter: grayscale(100%);
}

.skr-marker-on-enter {
  filter: grayscale(100%);
  opacity: 20%;
}

.skr-marker-on-enter-active {
  /* transform: rotate(0.25turn); */
  opacity: 35%;
  transition: all 1000ms;

}
.skr-marker-on-enter-done {
  /* transform: rotate(-0.25turn); */
  opacity: 50%;
  filter: grayscale(100%);
}

/* Marker-getBO States */
.skr-marker-getBO-appear {
  opacity: 20%;
  transform: scale(1.5);
  transition: all 1000ms
}

.skr-marker-getBO-appear-active {
  transform: rotate(0.25turn) scale(1.25);
  opacity: 75%;
  transition: all 2000ms ease-out
}

.skr-marker-getBO-appear-done {
  transform: rotate(0.25turn) scale(1.25);
  opacity: 100%;
}

.skr-marker-getBO-enter {
  transform: scale(1.5);
  opacity: 20%;
}

.skr-marker-getBO-enter-active {
  transform: rotate(0.25turn);
  opacity: 75%;
  transition: all 2000ms;
}

.skr-marker-getBO-enter-done {
  transform: rotate(0.25turn) scale(1.25);
  opacity: 100%;
}


/* Marker-onBO States */
.skr-marker-onBO-appear {
  opacity: 50%;
  transform: scale(1.5);
}

.skr-marker-onBO-appear-active {
  filter: var(--skr-onBO-filter);
  transform: rotate(-1.25turn) scale(1.25);
  opacity: 100%;
  transition: all 2000ms ease-out
}

.skr-marker-onBO-appear-done {
  filter: var(--skr-onBO-filter);
  transform: rotate(-0.25turn) scale(1.25);
  opacity: 100%;
}

.skr-marker-onBO-enter {
  filter: var(--skr-onBO-filter);
  opacity: 20%;
}

.skr-marker-onBO-enter-active {
  transform: rotate(1turn);
  opacity: 75%;
  transition: all 2000ms ease-out;
}

.skr-marker-onBO-enter-done {
  transform: rotate(-0.25turn) scale(1.25);
  opacity: 100%;
}

/* Marker-OFF States */
.skr-marker-off-appear {
  /* transform: rotate(-0.25turn); */
  filter: grayscale(100%);
  opacity: 50%;
}

.skr-marker-off-appear-active {
  /* transform: rotate(1turn); */
  opacity: 25%;
  transition: all 2000ms;
}

.skr-marker-off-appear-done {
  /* transform: rotate(-0.25turn); */
  opacity: 0%;
  filter: grayscale(100%);
}

.skr-marker-off-enter {
  filter: grayscale(100%);
  opacity: 50%;
}

.skr-marker-off-enter-active {
  /* transform: rotate(0.25turn); */
  opacity: 25%;
  transition: all 1000ms;

}
.skr-marker-off-enter-done {
  /* transform: rotate(-0.25turn); */
  opacity: 0%;
  filter: grayscale(100%);
}


/* Swiper Transition States */
.skr-fader-trans-appear {
  opacity: 0.5;
}

.skr-fader-trans-appear-active {
  opacity: 1;
  transition: all 500ms ease-in
}

.skr-fader-trans-appear-done {
  opacity: 1;
}

.skr-fader-trans-enter {
  opacity: 0;
}

.skr-fader-trans-enter-active {
  opacity: 1;
  transition: all 500ms ease-out;
}

.skr-fader-trans-enter-done {
  opacity: 1;
}

.skr-fader-trans-exit {
  opacity: 1;
}

.skr-fader-trans-exit-active {
  opacity: 0;
  transition: all 2000ms;
}

.skr-fader-trans-exit-done {
  opacity: 0%;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
/* Cluster */

.skr-cluster > * {
  /* Set Minimum Font Size */
  max-height: 800px;
  user-select: auto ;
  pointer-events: auto; 
  overflow-x: hidden;
  overflow-y: auto;
}

.skr-header-block {
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: calc(var(--skr-font-min) * 1.3) !important;
  /* font-size: 1.3rem; */
  line-height: 1;
  letter-spacing: 0.023vw;  
}

.skr-section-title {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: start;
  font-style: normal;
  font-weight: 500;
  font-size: calc(var(--skr-font-min) * 1.2) !important;
  /* font-size: 1.2rem; */
  line-height: 1;
  letter-spacing: 0.023vw;  
  /* border-bottom: 1px solid #8c8b8b; */
}

.skr-product-row {
  margin-top: 2px;
  font-style: normal;
  font-size: calc(var(--skr-font-min) * 1.0) !important;
  line-height: 1;
  letter-spacing: 0.023vw; 
  text-align: start ;
}

.skr-modal-close {
  padding-right: 10px;
  text-align: end;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.023vw;  
}

.skr-heading {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: calc(var(--skr-font-min) * 1.5) !important;
  /* font-size: 1.5rem; */
  line-height: 1;
  letter-spacing: 0.023vw;  
}


/* 

POPUP CONTROLLER STYLES 

*/

body {
  margin: 0;
  padding: 0;
}

#skr-ext-container_popup {
  margin: 0;
  padding: 0;
  width: 300px;
  height: 200px;
  background-color: #fff;

  text-align: center;
  font-family: "Roboto";
  color: rgb(43, 42, 42);
  user-select: none;
}

.skr-ext-popup_name {
  padding: 20px 0;
  font-size: 20px;
}

.skr-ext-toggle {
  margin: 10px auto;
  padding: 10px;
  width: fit-content;
  border-radius: 3px;

  font-size: 20px;
  background-color: red;
  cursor: pointer;
}

/* SKR-Player */
/* Setting width fixes bug in swiper component */
.skr-main-container {
  width: 100vw;
  height: 100vh;
  /* max-width: 600px;    */
}
/* .skr-player-container{
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw !important;
  max-width: 100vw !important; 
  height: 100%;
  max-height: 100%;
  margin-left: 0px;
  object-fit: contain;
} */

.skr-player-container {
  display: flex;
  width: 100vw !important;
  height: 50vh !important;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.skr-player-logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 5;
}
.react-player {
  width: 100%;
  height: 50%;
}

.player-overlay {
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0;
  top: 0;
  z-index: 5;
}
 .player-overlay-morevdo {
   position: absolute;
    width: 100%;
    height: 111px;
    left: 0;
    top: 48%;
    z-index: 5
  }
