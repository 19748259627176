@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
/* color: #fff; */
font-family: 'Roboto', sans-serif;
}
body {
  --skr-red: #FC2504;
  --skr-text-color:  #fff; 
  margin: 0;
  padding: 0;
}

.app-container {
  background: #343541;
  display: flex;
  flex-wrap: none; /* Allow items to wrap to the next line if needed */
  height: 100vh !important;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  padding: 0 !important;
  overflow-x: hidden;
  }

.container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
}

.left-wrapper {
  /* Just wraps side-bar */
  display: flex;
  flex: 0 0 300px;
  flex-direction: column;
  max-width: fit-content;
  justify-content: space-between;
  padding-left: 0 !important;
  }
    
.main-container {
display: flex;
flex-shrink: 1;
flex-grow: 1;
flex-direction: column;
justify-content: space-between;
align-items: center;
text-align: center;
height: 100%; 
max-width: 100%;
}

nav {
border: solid 0.5 #ffffff73;
padding: 10px;
margin: 10px;
}

.history {
padding: 10px;
margin: 10px;
height: 100%;
}

.history-title {
  list-style-type: none;
  padding: 5px 0; 
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  }
  
.new-skr {
  display:flex;
  align-items: center;
  gap: 1px;
}  
.new-skr-title {

  padding: 5px 0; 
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  }

.user-info {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: auto;
  border-top: #ffffff73 solid 1.5px;
  padding: 5px 0; 
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  }
  
/* .history li {
list-style-type: none;
padding: 15px 0; 
cursor: pointer;
} */

/* SKR-NEW Styles */
.empty-filler-box {
  width: 100%;
  height: 100%;
  border: 2px solid white;
}

.empty-product-box {
  width: 100%;
  height: 50vh;
  border: 2px solid white;
}

.empty-side-box {
  width: 0px;
  height: 100%;
  border: 2px solid white;
}
  
.info-panel {
  width: 150px;
  height: 250px;
  border: 2px solid white;
}

.skr-header-block {
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: calc(var(--skr-font-min) * 1.3) !important;
  /* font-size: 1.3rem; */
  line-height: 1;
  letter-spacing: 0.023vw;  
}

.skr-new .row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  text-align: center !important;
}

.skr-new .copy-wrapper {
  quotes: "“" "”";
  letter-spacing: .15rem;  
  line-height: 1.5;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-synthesis: none;
  color: #f5f5f7;
  position: relative;
  flex-grow: 0;
  text-align: center;
  align-items: center;
  padding-top: 30px;
  margin-top: 0;
  width: 100%;
  text-align: center !important;  
}

.skr-new .headline {
  quotes: "“" "”";
  font-style: normal;
  font-synthesis: none;
  /* display: flex; */
  pointer-events: none;
  margin: 0;
  padding: 0;
  text-align: center !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  flex-shrink: 1;
  color: #f5f5f7;
  font-size: 34px;
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: .15rem;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.skr-new .sub-headline {
  quotes: "“" "”";
  font-style: normal;
  font-synthesis: none;
  /* display: flex; */
  pointer-events: none;
  margin: 0;
  padding: 0;
  text-align: center !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  flex-shrink: 1;
  color: #f5f5f7;
  font-size: 22px;
  line-height: 1.16667;
  font-weight: 400;
  letter-spacing: .1rem;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  margin-top: 5px;
}

.search-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  align-items: center;
  /* width: calc(100% - 20px); */
  width: 75%;
  max-width: 100vw;
  height: 125px;
  overflow-y: hidden;
  position: relative;
  padding-left: 5px !important;
}

.search-container textarea {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.20);
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 54px 55px,
    rgba(0, 0, 0, 0.05) 0 -12px 30px,
    rgba(0, 0, 0, 0.05) 0 4px 6px,
    rgba(0, 0, 0, 0.05) 0 12px 3px,
    rgba(0, 0, 0, 0.05) 0 -3px 55px;
  resize: none; 
  overflow-y: hidden;
  min-height: 45px; /* Set a minimum height for the input */
  /* max-height: 68px; Set a new maximum height for the input */
  /* white-space: pre-wrap; Allow text wrapping */
  word-wrap: break-word; /* Allow word wrapping */
  overflow-wrap: normal; /* Allow word wrapping for long words */
  padding: 10px; 
  color:#fff !important;
  }
  
  .search-container textarea::placeholder {
    font-style: italic; /* Set the font style */
    /* Other placeholder styles... */
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8) !important;
  }
  
  textarea:focus {
  outline: none;
  }

.search-button  {
  display: flex;
  flex-shrink: 0;
  width: 80px;
  padding: 10px;
  margin-left: 10px;
  background: var(--skr-red);
  border: solid 0.5px var(--skr-red);
  border-radius: 5px;
  cursor: pointer;
  color: rgba(255, 255, 255);
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

/* SIDE BAR CSS */
/* Default styles for the sidebar */
.side-bar {
  height: 100vh;
  width: 244px;
  background: #202123;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.4s ;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  }

/* Media query for screen sizes */
@media (min-width: 1200px) {
  /* Styles for larger screens */
  .side-bar {
    /* Update styles for larger screens */
    width: 300px;
    height: 100vh;
    background: #202123;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.4s;

    /* Additional styles... */
  }
}
@media (max-width: 768px) {
  .side-bar {
    /* Styles for smaller screens */
    width: 255px;
    height: 100vh;
    background: #202123;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.4s;
    }
}

@media (max-width: 576px) {
  .side-bar {
    /* Styles for smaller screens */
    width: 190px;
    height: 100vh;
    background: #202123;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.4s;
    }
}

/* Styles for the collapsed sidebar */
.side-bar.collapsed {
  width: 0;
  overflow: hidden;
}

.side-bar-new-skr {
  display: flex;
  border: none;
  background: transparent;
  font-size: 24px;
  font-weight: 600;
  margin: 10px; 
  cursor: pointer;
  } 
  
.side-bar-toggle-hamburger {
  border: none;
  background: transparent;
  font-size: 24px;
  font-weight: 600;
  margin: 10px; 
  cursor: pointer;
  } 

.side-bar-toggle-collapse {
  display: flex;
  /* justify-self: right; */
  /* text-align: right; */
  border: none;
  background: transparent;
  font-size: 24px;
  font-weight: 600;
  margin: 10px; 
  cursor: pointer;
  } 
  

  /* Top Bar Navs */
  
  .skr-top-bar-container {
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between; /* Aligns items to either end */
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50px;
  }

    /* top right bottom left */
  .skr-top-bar-hamburger {
    border: none;
    background: transparent;
    font-size: 30px;
    font-weight: 600;
    margin: 10px 0 0px 5px; 
    cursor: pointer;
    } 

  .skr-top-bar-marker {
    margin-top: 10px ; 
    margin-right: 5px ; 
    height: 50px;
  }
  
/* Offer / Product Container CSS */
/* See skr_comp_styles for component styles */
.skr-swiper-container {
  width: 100%;
  max-width: 100%;
  cursor: hand;
}

.offers-container {
  display: flex;
  background: #202123;
  border-radius: 5px;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  align-items: left;
  max-height: 50vh;
  width: 100%;
  max-width: 100%;
  align-self: stretch;
}
  
/* MESSAGING COMPONENT */
.messaging-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: left;
  max-height: 50vh;
  /* max-height: 100%; */
  width: 100%;
  align-self: stretch;
}

.new-skr-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 100vh;
  max-height: 100%;
  align-self: center;
}
  

.feed {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px); 
  overflow-y: scroll;
  padding-left: 10px;
  padding-right: 10px;
  }
  
.feed li {
display: flex;
line-height: 1.25;
}

.feed-message {
  padding-left: 25px;
  min-width: 100px;
  font-size: 18px;
  font-weight: 450;
  text-align: left;
} 
  
.message {
  min-width: 100px;
  font-size: 18px;
  font-weight: 450;
} 

table {
  border-collapse: separate;
  border-spacing: 8px;
}

table td,
table th {
  padding: 8px;
}

/* REMOVE THIS AFTER DEV */
.markdown-content p{
  color: #f5f5f7 !important;
  text-align: left !important;
  min-width: 100px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
} 

.message .markdown-content p{
  color: #f5f5f7 !important;
  min-width: 100px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
} 

.message .markdown-content th{
  min-width: 100px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
} 

.message .markdown-content td{
  min-width: 100px !important;
  font-size: 16px !important;
  font-weight: 450 !important;
} 

.feed p.role {
min-width: 100px;
font-size: 20px;
font-weight: 600;
margin-top: 25px;
} 

.feed .message-block {
  display: inline-block;
} 

.feed p.name {
  min-width: 100px;
  font-size: 21px;
  font-weight: 700;
}

.feed p {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  }
  
.feed p.role.assistant {
  min-width: 100px;
  font-size: 18px;
  background-color: red;
  } 

.feed p.role.user {
  min-width: 100px;
  font-size: 18px;
  background-color: rgb(0, 38, 255);
  } 
  

.input-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  align-items: center;
  /* width: calc(100% - 20px); */
  width: 100%;
  max-width: 100vw;
  height: 125px;
  overflow-y: hidden;
  position: relative;
  padding-left: 5px !important;
}

.input-container textarea {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.20);
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 54px 55px,
    rgba(0, 0, 0, 0.05) 0 -12px 30px,
    rgba(0, 0, 0, 0.05) 0 4px 6px,
    rgba(0, 0, 0, 0.05) 0 12px 3px,
    rgba(0, 0, 0, 0.05) 0 -3px 55px;
  resize: none; 
  overflow-y: hidden;
  min-height: 45px; /* Set a minimum height for the input */
  /* max-height: 68px; Set a new maximum height for the input */
  /* white-space: pre-wrap; Allow text wrapping */
  word-wrap: break-word; /* Allow word wrapping */
  overflow-wrap: normal; /* Allow word wrapping for long words */
  padding: 10px; 
  color:#fff !important;
  }
  
  .input-container textarea::placeholder {
    font-style: italic; /* Set the font style */
    /* Other placeholder styles... */
    font-size:20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8) !important;
  }
  
  textarea:focus {
  outline: none;
  }

.input-container button  {
  display: flex;
  flex-shrink: 0;
  width: 80px;
  padding: 10px;
  margin-left: 10px;
  background: transparent;
  border: solid 0.5px #ffffff6d;
  border-radius: 5px;
  cursor: pointer;
  color: rgba(255, 255, 255);
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.progress,.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #343a40 !important;
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #343a40;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  font-size: var(--bs-progress-font-size);
  height: var(--bs-progress-height);
  overflow: hidden
}

.progress-bar {
  background-color: var(--bs-progress-bar-bg) !important;
  color: var(--bs-progress-bar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  transition: var(--bs-progress-bar-transition);
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 30px;
}

.input-container button.active  {
  /* Change the following property to the color you'd like to set */
  background: var(--skr-red);
  border: solid 0.5px var(--skr-red);

}
.info {
color: rgba(255, 255, 255, 1);
align-items: center;
}  

.flex-container {
  display: flex;
  flex-direction: column;
}

.container {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  gap: 10px;
}

.row1 {
  grid-row: 1;
  grid-column: 1;
}

.row2 {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.row3 {
  grid-row: 3;
  grid-column: 1;
}

.skr-prod-description {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 80;
  font-size: calc(var(--skr-font-min) * 1.0) !important;
  line-height: 1;
  letter-spacing: .10rem;  
  text-transform: none;
  text-align: start ;
}

